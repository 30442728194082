import React from 'react';
import useLoginLink from '../hooks/useLoginLink';

export default function LoginPage() {
    const loginLink = useLoginLink();

    return (
        <>
            <h1>Login Page</h1>
            <div><a href={loginLink}>SSO Login Link</a></div>
        </>
    );
}
